import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import "../../css/custom-css/custom-products-services.css"

import Header from "../../layouts/header4"
import Footer from "../../layouts/footer4"

import Layout from "../../components/custom-components/Layout"
import BannerHeader from "../../components/custom-components/BannerHeader"

import bg from "../../images/products/banner-produk.png"
import Seo from "../../components/seo"

const AmpFireHpv = () => {
  return (
    // <Layout>
    <div className="skin-1 index">
      <Seo title="AmpFire HPV Screening HR 16/18 Kit" />

      <Header productsPage />

      <BannerHeader
        background={bg}
        title="AmpFire HPV Screening HR 16/18 Kit"
        servicesDetails
      />
      <div className="content-block">
        <div className="section-full content-inner-custom">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5">
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Products</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li className="">
                      <Link to="/products/hpv-xpress-matrix">
                        HPV XpressMatrix™ Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/sars-cov-2-real-time">
                        Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/sars-cov-2-saliva-nucleic">
                        Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid Test Kit
                      </Link>
                    </li>
                    <li className="active">
                      <Link to="/products/ampfire-hpv-screening">
                        AmpFire HPV Screening HR 16/18 Kit
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/indigen-real-time">
                        INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/products/diago-t790m">
                        DIAGO T790M Mutation Detection Kit
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <div className="icon-content">
                    <h2 className="dlab-title">Our Services</h2>
                  </div>
                </div>
                <div className="widget sidebar-widget ext-sidebar-menu widget_nav_menu">
                  <ul className="menu">
                    <li>
                      <Link to="/services/hpv-genotyping">
                        HPV DNA Genotyping Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/hpv-highrisk">
                        HPV DNA High Risk Test
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/liquid-based-cytology">
                        Liquid Based Cytology
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/services/co-testing">Co-Testing</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-9 col-lg-8 col-md-7 m-b30">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <StaticImage src="../../images/products/ampfire.png" />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <div class="row m-b30">
                      <div class="col-lg-12">
                        <div class="dlab-tabs  product-description tabs-site-button">
                          <h4 className="dlab-title mt-2">
                            AmpFire HPV Screening HR 16/18 Kit
                          </h4>
                          <Tabs>
                            <TabList className="nav nav-tabs ">
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Description</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Test Principle</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Intended Use</Link>
                              </Tab>
                              <Tab selectedClassName="tab-active">
                                <Link to="#g">Additional Information</Link>
                              </Tab>
                            </TabList>

                            <TabPanel class="tab-pane">
                              <p className="text-justify">
                                Human Papillomavirus (HPV) is a small
                                non-enveloped double-stranded DNA virus
                                classified as papillomaviridae family. In
                                humans, HPV is the most common sexually
                                transmitted infection leading to genital warts
                                and even cervical cancer. Data show that
                                cervical cancer in Indonesia ranks 2 after
                                breast cancer, with a total number of incidences
                                of 36.600. Among other subtypes of HPV, HPV
                                high-risk subtypes 16 and 18 are predicted 70%
                                leading to cervical cancer. The mitigation can
                                be done by regular testing with high-performance
                                HPV DNA kit. PT KalGen DNA proudly presents
                                AmpFire HPV Screening 16/18/HR. This kit is an
                                isothermal-based nucleic acid amplification kit
                                for the qualitative detection of high-risk
                                subtypes of HPV. Specific primers for High-risk
                                HPV and fluorescent probes were used to amplify
                                regions of viral genomic DNA covering E6/E7
                                region. This kit is an open system for all
                                Real-time PCR machines which have FAM, HEX, ROX,
                                and CY5 Channels.
                              </p>
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <StaticImage
                                src="../../images/products/ampfire-workflow.png"
                                layout="fullWidth"
                                height={300}
                              />
                            </TabPanel>

                            <TabPanel class="tab-pane ">
                              <p class="m-b10">
                                AmpFire HPV Screening 16/18/HR is an
                                isothermal-based in vitro diagnostic kit (IVD)
                                of nucleic acid amplification with a real-time
                                fluorescence detection method to detect the
                                presence of DNA from the Human Papillomavirus
                                (HPV) genotypes 16, 18, 31, 33, 35, 39, 45, 51,
                                52, 53, 56, 58, 59, 66, 68 qualitatively, as
                                well as identification of the HPV16 and HPV18
                                subtypes (the two most dangerous HPV genotypes)
                                from various types of samples.
                              </p>
                            </TabPanel>

                            <TabPanel>
                              <p class="m-b10 font-weight-bold">
                                Reference Article:
                              </p>
                              <p className="ml-4">
                                <ol>
                                  <li>
                                    Bzhalava, D., Carina, E., Joakim, D. 2015.
                                    International standardization and
                                    classification of human papillomavirus
                                    types. J.Virology. 476.
                                  </li>
                                  <li>
                                    https://gco.iarc.fr/ accessed on November
                                    20,2022
                                  </li>
                                  <li>
                                    Acheampong,L.K., et al.2021. Determining the
                                    prevalence of high-risk human papillomavirus
                                    infection using a novel cervical precancer
                                    screening approach in incarcerated women at
                                    the Nsawam Medium Security Prison, Ghana.
                                    J.ecancer medical science. 15.
                                  </li>
                                  <li>
                                    Hu,L.,et al.2022. Evaluation of dry specimen
                                    transport and processing time using an
                                    isothermal amplification high-risk human
                                    papillomavirus assay. J.Gynecology and
                                    Obstetrics Clinical Medicine.
                                  </li>
                                  <li>
                                    Juliana,N.C.A., et al. 2020. Detection of
                                    high-risk human papillomavirus (HPV) by the
                                    novel AmpFire isothermal HPV assay among
                                    pregnant women in Pemba Island, Tanzania.
                                    J.Pan African Medical.37(183)
                                  </li>
                                  <li>
                                    Goldstein,A., et al.2020. A rapid,
                                    high-volume cervical screening project using
                                    self-sampling and isothermal PCR HPV
                                    testing. J. Infectious Agents and
                                    Cancer.15(64).
                                  </li>
                                </ol>
                              </p>
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer hideContactInfo />
      </div>
    </div>
  )
}

export default AmpFireHpv
